import React from 'react';
import { CtaSectionExtended } from 'modules/cta-section-extended';

export const WebCtaSectionExtended = () => {
    return (
        <CtaSectionExtended title="ctaExtendedTitle">
            <p>
                Empower your business with custom,{' '}
                <strong>scalable web solutions</strong>, exceptional UX/UI
                design, and rigorous QA testing to ensure flawless performance.
            </p>
            <ul>
                <li>
                    Free consultation to discuss your web platform and design
                    needs
                </li>
                <li>
                    Discover how full-stack JS development can boost performance
                    and scalability
                </li>
                <li>
                    Expertise in building custom web platforms with modern
                    frameworks like React, Vue, NodeJS, AWS and more
                </li>
                <li>
                    Enhance user experience with intuitive UX/UI design tailored
                    to your audience
                </li>
                <li>
                    Ensure quality and reliability with comprehensive QA testing
                </li>
                <li>
                    DevOps support with AWS and Terraform for seamless
                    deployment and scalability
                </li>
            </ul>
        </CtaSectionExtended>
    );
};
